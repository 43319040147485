import React from "react";
import Layout from "../layout";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Github from "../components/github";
import { Helmet } from "react-helmet";
export default ({ uri }) => {
  const data = useStaticQuery(graphql`
    query {
      profile: file(relativePath: { eq: "profile.png" }) {
        childImageSharp {
          fluid(maxWidth: 1424) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      job1: file(relativePath: { eq: "job1.png" }) {
        childImageSharp {
          fluid(maxWidth: 476) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      job2: file(relativePath: { eq: "job2.png" }) {
        childImageSharp {
          fluid(maxWidth: 476) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      job3: file(relativePath: { eq: "job3.png" }) {
        childImageSharp {
          fluid(maxWidth: 476) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      job4: file(relativePath: { eq: "job4.png" }) {
        childImageSharp {
          fluid(maxWidth: 476) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      job5: file(relativePath: { eq: "job5.png" }) {
        childImageSharp {
          fluid(maxWidth: 476) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout path={uri}>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>Mohamed's Porfolio &#129305;</title>
        <meta
          name="description"
          content="Full Stack Web Developer Portfolio."
        />
        <meta name="robots" content="nofollow" />
      </Helmet>
      <div className="work fade">
        <p className="work-intro">
          All paid work has been through Upwork. Click{" "}
          <a
            href="https://www.upwork.com/o/profiles/users/~01d484ce194ebe6679/"
            className="normal"
          >
            Here
          </a>{" "}
          to view profile.
        </p>
        <div className="image-container">
          <Img
            className="profile-sc"
            fluid={data.profile.childImageSharp.fluid}
            alt="profile-screenshot"
          />
        </div>
        <div className="work-examples">
          <div className="image-container">
            <Img fluid={data.job1.childImageSharp.fluid} alt="job-screenshot" />
          </div>
          <div className="image-container">
            <Img fluid={data.job2.childImageSharp.fluid} alt="job-screenshot" />
          </div>
          <div className="image-container">
            <Img fluid={data.job3.childImageSharp.fluid} alt="job-screenshot" />
          </div>
          <div className="image-container">
            <Img fluid={data.job4.childImageSharp.fluid} alt="job-screenshot" />
          </div>
          <div className="image-container">
            <Img fluid={data.job5.childImageSharp.fluid} alt="job-screenshot" />
          </div>
        </div>
      </div>
      <Github />
    </Layout>
  );
};
